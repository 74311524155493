import React from "react"
import Layout from "../components/LayoutComponent"
import Seo from "../components/SeoComponent"
import { useStaticQuery, graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import { useIntl, FormattedMessage } from "gatsby-plugin-react-intl"
import "../assets/writer.css"

const Writer = () => {
  const { backgroundImage } = useStaticQuery(graphql`
    query {
      backgroundImage: file(relativePath: { eq: "LANDSCAPE_WRITER.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, formats: [AUTO, WEBP, AVIF])
        }
      }
    }
  `)
  const pluginImage = getImage(backgroundImage)
  const intl = useIntl()
  return (
    <Layout>
      <Seo
        title={intl.formatMessage({ id: "pisac" })}
        canonicalUrl={"writer"}
      />
      <BgImage image={pluginImage} className="bg-writer"></BgImage>
      <article className="text-writer">
        <h1>
          <FormattedMessage id="writer-page.writer-h1" />
        </h1>
        <p>
          <FormattedMessage id="writer-page.writer-one" />
        </p>
      </article>
    </Layout>
  )
}

export default Writer
